.cell {
  padding: 0.1rem 0.2rem !important;
  outline: none;
  &:hover {
    cursor: pointer;
    background-color: rgb(235, 236, 240);
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    padding: 1px 2px 1px 0;
    border-radius: 4px;
    color: $font-color;
  }
}

.cellInput {
  position: relative;
  outline: none;

  input {
    background-color: $gray-200;
    border: 2px solid transparent;
    border-radius: 5px;
    outline: none;
  }
}

.controlIcons {
  position: absolute;
  right: 0;
  z-index: 99;
  margin-top: 1px;

  span {
    padding: 6px;
    background: $gray-200;
    border-radius: 3px;
    border: 1px solid #f3f3f3;
    cursor: pointer;

    &:hover {
      background: #c0392b;
    }
  }
  //.material-symbols-outlined{
  //  &:hover {
  //    color: white;
  //  }
  //}
}

.my-custom-class .tooltip-inner {
  background-color: $primary;
}

.right .arrow::before {
  border-right-color: $primary;
}

.top .arrow::before {
  border-top-color: $primary;
}

.border-bottom-dashed {
  border-bottom: 1px dashed;
}

.multiple-tags {
  position: relative;
  .tag-chip-list {
    border-left-style: solid;
  }
  .mat-form-field-wrapper{
    padding-bottom: 8px !important;
  }
}
