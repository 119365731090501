@import "colors";

.input-border-none {
  border: none;
}

.primary-input-radio {
  border-radius: $border-radius-sm;
  padding: 0.7rem 1.2rem;
  border: 1px solid $gray-600;
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 4px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid $secondary;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    border: 2px solid $secondary;
    background-color: $secondary;
  }
}

//checkbox css customized
.custom-checkbox-primary {
  border-radius: $border-radius-sm;
  padding: 0.7rem 1.2rem;
  border: 1px solid $gray-600;
  input {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 15px;
    &::before {
      content: "";
      width: 18px;
      height: 18px;
      position: absolute;
      left: -1px;
      top: -2px;
      background-color: #fff;
      border: 2px solid $secondary;
      box-sizing: border-box;
      border-radius: 4px;
    }
    &:checked {
      &::before {
        background-color: $secondary;
        border: 1px solid $secondary;
        background-image: url("/assets/landing-preview/assets/icons/checked.svg");
        background-size: 75% auto;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
