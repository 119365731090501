@import "colors";
@import "mixins";

.btn {
  font-size: 14px;
  box-shadow: none;
  text-transform: unset;
  font-weight: 600;
  padding: .7rem .5rem; // .375 .75
  border-radius: $border-radius;


  &.btn-icon {
    &:focus {
      box-shadow: none;
    }
  }

  &.btn-link {
    box-shadow: none;
    color: black;

    &:hover {
      color: $primary;
    }
  }

  &.btn-primary {
    background: $primary;
    border-color: unset;
    color: $text_color_dark;
  }

  &.btn-outline-primary {
    color: $primary;
    border-color: unset;

    &:hover {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }

    &:active {
      color: $white;
      background-color: $primary !important;
      border-color: $primary !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(red($primary), green($primary), blue($primary), 0.5);
    }

    &.active {
      color: $white;
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  &.btn-default{
    border: 1px solid gainsboro;
    border-radius: 3px;
    padding: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    img{
      width: 28px;
      height: 28px;
    }
    span{
      @include help-text;
    }
  }

  &.btn-gray {
    color: $white;
    background-color: $gray;
  }

  &.btn-outline-gray {
    color: $gray;
    border-color: $gray;

    &:hover {
      color: #000;
      border: 2px solid #000;
      font-weight: 500;
    }

    &:active {
      color: #000;
      border: 2px solid #000;
      font-weight: 500;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(red(#000), green(#000), blue(#000), 0.5);
    }

    &.active {
      color: #000;
      border: 2px solid #000;
      font-weight: 500;
    }
  }
}

.google_signup{
  img{
    width: 207px;
    height: 45px;
  }
}
/*
 Upload Btn Style
*/
.upload-doc-btn{
  width: 100%;
  height: 60px;

  border: 1px dashed $gray;
  span{
    @include desc-heading;
  }
}
//Hover
.upload-doc-btn:hover{
  background: $background_light;
}

.btn:not([class*=btn-outline-]) {
  border: 1px solid transparent;
}

