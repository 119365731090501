a {
  span {
    color: $primary
  }
  &:hover:not(.nav-link) {
    span {
      text-decoration: underline;
    }
  }
}

.btn-link:hover {
  text-decoration: underline;
}
