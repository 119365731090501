// update <html> tag border
html {
  border: none !important;
}

// Blur effect
.blur {
  box-shadow: $blur-box-shadow;
  -webkit-backdrop-filter: $blur-backdrop-filter;
  backdrop-filter: $blur-backdrop-filter;
  background-color: $card-background-blur !important;

  &.saturation-less {
    -webkit-backdrop-filter: $blur-backdrop-filter-less;
    backdrop-filter: $blur-backdrop-filter-less;
  }

  &.blur-rounded {
    border-radius: $blur-border-radius-rounded;
  }

  &.blur-light {
    background-color: $blur-light-background;
  }
  &.blur-dark {
    background-color: $blur-dark-background;
  }
}

.shadow-blur {
  box-shadow: $shadow-blur-box-shadow !important;
}

.shadow-card {
  box-shadow: $card-box-shadow !important;
}

.navbar-blur {
  -webkit-backdrop-filter: $blur-backdrop-filter;
  backdrop-filter: $blur-backdrop-filter;
  background-color: rgba($white, .58) !important;
}

.blur-section {
  -webkit-backdrop-filter: $blur-backdrop-filter;
  backdrop-filter: $blur-backdrop-filter;

  &.blur-gradient-primary {
    @include gradient-directional(rgba($primary-gradient, .95) 0%, rgba($primary-gradient-state, .95) 100%, $deg: 195deg);
  }
}

// Transition on hover
* {
  &.move-on-hover {
    -webkit-transition: $move-transition;
    transition: $move-transition;
    overflow: $move-overflow;
    -webkit-transform-origin: $move-transform-origin;
    transform-origin: $move-transform-origin;
    transform-origin: $move-transform-origin;
    -webkit-transform: $move-transform;
    transform: $move-transform;
    -webkit-backface-visibility: $move-backface-visibility;
    backface-visibility: $move-backface-visibility;
    will-change: $move-will-change;

    &:hover {
      -webkit-transform: $move-hover-transform;
      transform: $move-hover-transform;
    }
  }

  &.gradient-animation {
    background: $gradient-animation-background;
    background-size: $gradient-animation-bg-size !important;
    animation: $device-wrapper-animation;
  }
}

//hr {
//  border-top: none;
//  height: 1px;
//}

// Vertical Line
hr.vertical {
  position: absolute;
  background-color: $hr-bg-color;
  height: 100%;
  right: 0;
  top: 0;
  width: 1px;

  &.light {
    background-image: $hr-bg-color-light;
  }

  &.dark {
    background-image: $hr-bg-color-dark;
  }

  &.gray-light {
    background-image: $hr-horizontal-bg-image-gray-light;
  }
}

// Horizontal Line
hr.horizontal {
  background-color: $hr-bg-color;

  &.light {
    background-image: $hr-bg-color-light;
  }
  &.dark {
    background-image: $hr-bg-color-dark;
  }

  &.gray-light {
    background-image: $hr-horizontal-bg-image-gray-light;
  }
}

// lock style
.lock-size {
  width: 1.7rem;
  height: 1.7rem;
}

.border-radius-xs {
  border-radius: $border-radius-xs;
}

.border-radius-sm {
  border-radius: $border-radius-sm;
}

.border-radius-md {
  border-radius: $border-radius-md;
}

.border-radius-lg {
  border-radius: $border-radius-lg;
}

.border-radius-xl {
  border-radius: $border-radius-xl;
}

.border-radius-2xl {
  border-radius: $border-radius-2xl;
}

.border-radius-section {
  border-radius: $border-radius-section;
}

.border-bottom-end-radius-0 {
  border-bottom-right-radius: 0;
}

.border-top-end-radius-0 {
  border-top-right-radius: 0;
}

.border-bottom-start-radius-0 {
  border-bottom-left-radius: 0;
}

.border-top-start-radius-0 {
  border-top-left-radius: 0;
}

.border-dashed{
  border-style: dashed;
}

.z-index-sticky {
  z-index: $zindex-sticky;
}


// moving waves animation
.waves {
  position: $waves-position;
  width: $waves-width;
  height: $waves-height;
  margin-bottom: $waves-margin-bottom;
  /*Fix for safari gap*/
  min-height: $waves-min-height;
  max-height: $waves-max-height;

  &.waves-sm {
    height: $waves-height-sm;
    min-height: $waves-min-height-sm;
  }

  &.no-animation {
    .moving-waves > use {
      animation: none;
    }
  }
}

.wave-rotate {
  transform: $waves-rotate;
}
/* Animation for the waves */
.moving-waves > use {
  animation: $moving-waves-animation;
}

.moving-waves > use:nth-child(1) {
  animation-delay: $moving-waves-child-1-delay;
  animation-duration: $moving-waves-child-1-duration;
}

.moving-waves > use:nth-child(2) {
  animation-delay: $moving-waves-child-2-delay;
  animation-duration: $moving-waves-child-2-duration;
}

.moving-waves > use:nth-child(3) {
  animation-delay: $moving-waves-child-3-delay;
  animation-duration: $moving-waves-child-3-duration;
}

.moving-waves > use:nth-child(4) {
  animation-delay: $moving-waves-child-4-delay;
  animation-duration: $moving-waves-child-4-duration;
}

.moving-waves > use:nth-child(5) {
  animation-delay: $moving-waves-child-5-delay;
  animation-duration: $moving-waves-child-5-duration;
}

.moving-waves > use:nth-child(6) {
  animation-delay: $moving-waves-child-6-delay;
  animation-duration: $moving-waves-child-6-duration;
}
@keyframes move-forever {
  0% {
    transform: $moving-waves-keyframe-0;
  }

  100% {
    transform: $moving-waves-keyframe-100;
  }
}
/*Shrinking for mobile*/
@include media-breakpoint-down(md) {
  .waves {
    height: $waves-mobile-height;
    min-height: $waves-mobile-height;
  }

  hr.horizontal {
    background-color: $hr-bg;

    &:not(.dark) {
      background-image: $hr-bg-image;
    }

    &.vertical {
      transform: $hr-transform;
    }

    &.dark {
      background-image: $hr-bg-dark-image;
    }
  }
}

.overflow-visible {
  overflow: visible !important;
}

// popover title
.popover {
  .popover-header {
    font-weight: 600;
  }
}


// Background Sizes
.bg-cover{
  background-size: cover;
}

// Mask Class
.mask {
  position: $mask-position;
  background-size: $mask-bg-size;
  background-position: $mask-bg-position;
  top: $mask-top;
  left: $mask-left;
  width: $mask-width;
  height: $mask-height;
  opacity: $mask-opacity;
}


// Cursor Pointer Class
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

// Translate 50% Class
.transform-translate-50{
  transform: translate(0,-50%)
}

// VR Pages

@include media-breakpoint-up(lg) {
  .virtual-reality {
    .sidenav {
      animation-name: $animation-name;
      animation-fill-mode: $animation-mode;
      animation-duration: $animation-duration;
      transform: $transform-scale;
      left: $position-left !important;
      position: absolute;
    }
  }
}

// Selectpicker

.choices {
  .choices__list {
    background: $input-background;
    background-size: $input-background-size;
    transition: $input-transition;

    &.choices__list--single {
      .choices__item--selectable {
        margin-bottom: 0.5rem;
      }

      &,
      &:focus {
        background-image: $input-background-image;
      }


    }

    &.choices__list--dropdown {
      background: $white;
    }
  }

  &.is-focused {
    .choices__list {
      background-size: $input-background-size-focus;
    }
  }
}

// Vertical border w/ pseudo elem

.border-right-after:after {
  content: "";
  position: absolute;
  right: 0;
  top: $border-right-after-top;
  height: $border-right-after-height;
  width: $border-right-after-width;
  border-right: 1px solid $border-right-after-border-color;
}

// sidebar caret RTL
.rotate-caret {
  .collapse {
    .nav-link:after {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

@media (min-width: 1200px){
  .g-sidenav-hidden.rtl .main-content {
    margin-right: 7rem!important;
  }
}

// perfectScrollbar on RTL page
.rtl {
  .ps__rail-y {
    right: auto !important;
    left: 0;
  }

  .start-0 {
    right: 0 !important;
  }
  .end-0 {
    left: 0 !important;
  }
}

.box-shadow-md {
  @media (min-width: 576px) {
    box-shadow: -6px 4px 9px -4px rgba(0, 0, 0, 0.2);
  }
}

.box-shadow {
  box-shadow: -6px 4px 9px -4px rgba(0, 0, 0, 0.2);
}

.box-shadow-2 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.box-shadow-3 {
  box-shadow: 0 2px 4px 0 rgba(176, 175, 175, 0.5) !important;
}

.box-shadow-4 {
  box-shadow: 0 5px 7px 0 rgba(99, 96, 96, 0.5);
  &:focus{
    box-shadow: 0 5px 7px 0 rgba(99, 96, 96, 0.5) !important;
  }
}

.box-shadow-5 {
  box-shadow: 4px 2px 4px 0 rgba(176, 175, 175, 0.5);
  z-index: 1;
}

.box-shadow-6 {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.box-shadow-7 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 1px 2px 5px 0 rgba(0, 0, 0, 0.5)  !important
}

.box-shadow-8 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25) !important;
}

.box-shadow-9 {
  box-shadow: 2px 2px 8px 2px #eae3e3 !important;
}

.lean-border-shadow-md {
  @media (min-width: 576px) {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }
}

.hover-primary:hover {
  color: $primary !important;

  span {
    color: $primary !important;
  }
}

.hover-primary:hover, .hover-primary:active, .hover-primary:hover>img {
  color: $primary;
  //filter: invert(32%) sepia(81%) saturate(1520%) hue-rotate(339deg) brightness(77%) contrast(94%);
}

.hover-color-white:hover {
  color: $white;
}

.border-1 {
  border: 1px solid #b9b9b9;
}

.bg-light-tint {
  background: #f9f9f9;
}

.box-border-dashed-light{
  border: 1px dashed $secondary !important;
}

.box-border-dashed-danger{
  border: 1px dashed $primary !important;
}

.text-break-all {
  word-break: break-all;
}
.required:after {
  content:"*";
  color: #C0392B;
  font-weight: bold;
}
.border-979797 {
  border: solid .5px #979797;
}

.marquee {
  width: 100%;
  white-space:nowrap;
  overflow-x: hidden;
  position: relative;
  min-height: 20px;
}

.marquee span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.marquee:active span,
.marquee:hover span {
  position: absolute;
  width: auto;
  left: 100%;
  overflow: visible;
  text-overflow: unset;
  transition: all 3s ease-out;
  transform: translate(-100%, -50%);
}

::-webkit-search-cancel-button{
  filter: invert(32%) sepia(81%) saturate(1520%) hue-rotate(339deg) brightness(77%) contrast(94%);
}

.red-filter {
  filter: invert(32%) sepia(81%) saturate(1520%) hue-rotate(339deg) brightness(77%) contrast(94%);
}

::-webkit-search-cancel-button{
  filter: invert(32%) sepia(81%) saturate(1520%) hue-rotate(339deg) brightness(77%) contrast(94%);
}

.hover-thumbnail:hover {
  @extend .border-primary;
  @extend .border-1;
  @extend .rounded;
}

.arrow-round {
  background: #979797;
  color: white;
  border-radius: 50%;
}

.beta-tag {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  div {
    position: relative;

    span {
      border: .5px solid #979797;
      font-size: 10px;
      padding: .5px 5px;
      border-radius: 4px;
      position: absolute;
      right: 8px;
      top: -8px;
    }
  }
}


.custom-theme .ngx-slider {
  margin: 0 0 0 0 !important;
}

.custom-theme .ngx-slider .ngx-slider-bar-wrapper {
  margin-top: 0 !important;
  height: 5px !important;
  padding-top: 0 !important;
}

.custom-theme .ngx-slider .ngx-slider-bar {
  height: 5px !important;
  background: #E0E0E0 !important;
}

.custom-theme .ngx-slider .ngx-slider-selection {
  background: $secondary !important;
  height: 5px !important;
}

.custom-theme .ngx-slider .ngx-slider-pointer {
  top: -10px !important;
  background-color: $white !important;
  height: 26px !important;
  width: 26px !important;
  border-radius: 50%;
  padding: 4px;
  border: 2px solid #12131433 !important;
  position: relative;

  &:after {
    content: "";
    display: inline-block !important;
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: $secondary !important;
    height: 14px !important;
    width: 14px !important;
    border-radius: 50%;
  }
}


.flip-y {
  transform: scale(-1, 1);
}

/** Announcement kit notification bubble for new updates **/
a.announcekit-widget-badge {
  position: absolute !important;
  left: 8px !important;
  top: -2px !important;
}


ngx-daterangepicker-material {
  .btn {
    text-transform: capitalize !important;
  }
}

.border-dark-thin {
  border: 0.5px solid $font-color !important;
}

.border-secondary-hover {
  &:hover {
    border: $border-width solid $secondary !important;
  }
}

$scale-values: (1: 1.01, 2: 1.02, 3: 1.03);
@each $key, $value in $scale-values {
  .hover-transition-#{$key} {
    transition: all .3s ease;
    &:hover {
      transform: scale($value);
    }
  }
}
