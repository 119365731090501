.cdk-overlay-container {
  z-index: 99999 !important;
}

/**
  Used for removing the list-style of dragging-component
  Note: This is an external li, appended at the end of DOM
 */
.cdk-drag-preview{
  list-style: none;
}

.file-uploaded-list {
  padding-top: 2%;
  max-height: 400px;
  overflow-y: auto;

  // for drag and drop look
  .cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

  }

  ul{
    list-style-type: none;
    padding-left: 0 !important;
    li{
      padding: 2px 10px  !important;
      box-sizing: border-box;
      cursor: move;
      background: white;
    }
  }

  .delete-file {
    float: right;
  }
}

.custom-placeholder {
  background: white;
  border: dotted 2px #979797;
  min-height: 60px;
  border-radius: $border-radius;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-disabled {
  cursor: pointer !important;
}

.cdk-drag:not(.cdk-drag-disabled) {
  cursor: url(/assets/images/cursor_drag.svg), grab !important;
}

.cdk-drop-list-dragging {
  cursor: grabbing !important;
}

.cdk-drag-preview {
  .custom-component {
    .heading {
      background-color: $light !important;
      border: 0.5px solid $secondary;
    }
  }
}
