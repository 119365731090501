@import "../colors";

/**
 * Colors
 */
$color_phone: #1b73e8;
$color_facebook: #3b5999;
$color_google: #dd4b39;
$color_twitter: #000000;
$color_youtube: #cd201f;
$color_linkedin: #0077B5;
$color_pinterest: #bd081c;
$color_instagram: #e4405f;
$color_snapchat: #FFFC00;
$color_flickr: #ff0084;
$color_quora: #b92b27;
$color_vine: #00b489;
$color_flickr: #ff0084;
$color_tumblr: #34465d;
$color_vk: #4c75a3;
$color_vimeo: #1ab7ea;
$color_foursquare: #f94877;
$color_skype: #00AFF0;
$color_stumbleupon: #eb4924;
$color_reddit: #ff5700;
$color_yelp: #af0606;
$color_weibo: #df2029;
$color_blogger: #f57d00;
$color_whatsapp: #25D366;
$color_telegram: #0088CC;
$color_wechat: #09b83e;
$color_line: #00c300;
$color_medium: #02b875;
$color_slack: #3aaf85;
$color_dribbble: #ea4c89;
$color_behance: #131418;
$color_tiktok: #000000;
$color_qqsocial: #979797;
$color_meetup: #F74660;
$color_customLink: #686868;
$color_threads: #000000;
$color_discord: #5865F2;
$color_clubhouse: #FFE450;
$color_email: #7F7F7F;

$color_snapchat_text: #5e5e5e;

$border_width: 2px;
$border_color_default: transparent;

.s-links {
  font-size: 12px;

  &:empty {
    display: none !important;
  }

  .item {
    .s-link {
      border: $border_width solid $border_color_default;
    }
  }

  &.square {
    width: 100%;

    .item {
      //width: 48%;
      //display: inline-block;
      //margin: 2px;
      display: inline-grid;
      width: 50%;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .s-link {
        height: 8rem;
        display: block;
        text-align: center;
        //overflow: hidden;
        margin: 2px;
      }

      .icon {
        float: none;
        margin: 0 auto;
        border: none;
        height: 61%;
      }
    }
  }

  &.circle {
    @extend .square;
    background-color: $card_background;
    margin-top: 0.4rem;

    &.business-card-icon-circle {
      background-color: transparent;
      margin-top: 0;
      width: unset;

      .icon {
        width: 2.6rem;
        height: 2.5rem!important;
        border-radius: 50%;
        background-size: 1.5rem;
        border: 0;
      }

      .business-card-s-link {
        height: unset !important;
      }
    }

    .s-link {
      height: 10.5rem !important;
    }

    .item {
      .icon {
        border: $border_width solid $border_color_default;
      }
    }

    .icon {
      //width: 7rem;
      //height: 7rem !important;
      width: 6rem;
      height: 6rem !important;
      border-radius: 50%;
      background-size: 2.5rem;
      //background-size: 3rem;
    }
    .text {
      color: #000;
      margin-top: 0.5rem;
    }
  }

  &.only-icons {
    @extend .square;
    background-color: $card_background;

    .item {
      width: 3rem;

      .s-link {
        height: 3rem;
        display: flex;
        border-radius: 50%;
      }

      .icon {
        width: 100%;
        background-size: auto;
        height: 90%;
      }
    }
  }

  .icon-grayscale {
    filter: grayscale(1);

    &:hover {
      filter: none;
    }

    &:active {
      filter: none;
    }
  }
}

.s-link {
  height: 5rem;
  display: flex;
  align-items: center;

  &.linear{
    .icon {
      height: 40%;
      width: 20%;
    }
  }

  &.business-card-s-link {
    height: unset !important;
  }

  .wrapper-linear{
    width: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3%;

    .wrapper-linear-icon{
      width: inherit;
      margin: 0;
    }
  }

  .icon {
    background-position: center center;
    background-repeat: no-repeat;
    height: 53%;
    width: 20%;
    margin: 3%;
    background-size: contain;
    border-right: 1px solid $white;
  }

  // Facebook
  &.facebook {
    background-color: $color_facebook;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/facebook_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_facebook;
      }
    }
  }

  // Phone
  &.phone {
    background-color: $color_phone;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/phone_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_phone;
      }
    }
  }

  // LinkedIn
  &.linkedIn {
    background-color: $color_linkedin;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/linkedin_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_linkedin;
      }
    }
  }

  // WhatsApp
  &.whatsApp {
    background-color: $color_whatsapp;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/whatsapp_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_whatsapp;
      }
    }
  }

  // Whatsapp Business
  &.whatsAppBusiness {
    background-color: $color_whatsapp;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/whatsAppBusiness.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_whatsapp;
      }
    }
  }

  // telegram
  &.telegram {
    background-color: $color_telegram;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/telegram_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_telegram;
      }
    }
  }

  // Skype
  &.skype {
    background-color: $color_skype;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/skype_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_skype;
      }
    }
  }

  // MeetUp
  &.meetup {
    background-color: $color_meetup;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/meetup_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_meetup;
      }
    }
  }

  // Quora
  &.quora {
    background-color: $color_quora;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/quora_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_quora;
      }
    }
  }

  // QQSocial
  &.qqSocial {
    background-color: $color_qqsocial;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/qq_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_qqsocial;
      }
    }
  }

  // Medium
  &.medium {
    background-color: $color_medium;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/medium_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_medium;
      }
    }
  }

  // WeChat
  &.weChat {
    background-color: $color_wechat;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/wechat_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_wechat;
      }
    }
  }

  // Line
  &.line {
    background-color: $color_line;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/line_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_line;
      }
    }
  }

  // SnapChat
  &.snapchat {
    background-color: $color_snapchat;

    .icon {
      //border-right: 1px solid $text_color_grey_mid !important;
      background-image: url("/assets/landing-preview/assets/icons/s-media/snapchat_w.svg");
    }
    .text {
      color: $color_snapchat_text;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_snapchat;
      }
    }
  }

  // TikTok
  &.tikTok {
    background-color: $color_tiktok;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/tiktok_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_tiktok;
      }
    }
  }

  // Yelp
  &.yelp {
    background-color: $color_yelp;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/yelp_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_yelp;
      }
    }
  }

  // Reddit
  &.reddit {
    background-color: $color_reddit;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/reddit_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_reddit;
      }
    }
  }

  // Tumblr
  &.tumblr {
    background-color: $color_tumblr;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/tumblr_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_tumblr;
      }
    }
  }

  // Instagram
  &.instagram {
    background-color: $color_instagram;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/instagram_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_instagram;
      }
    }
  }

  // Pinterest
  &.pinterest {
    background-color: $color_pinterest;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/pinterest_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_pinterest;
      }
    }
  }

  // Twitter
  &.twitter {
    background-color: $color_twitter;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/twitter_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_twitter;
      }
    }
  }

  // Youtube
  &.youtube {
    background-color: $color_youtube;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/youtube_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_youtube;
      }
    }
  }

  // Threads
  &.threads {
    background-color: $color_threads;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/threads_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_threads;
      }
    }
  }

  // Discord
  &.discord {
    background-color: $color_discord;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/discord_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_discord;
      }
    }
  }

  // Clubhouse
  &.clubhouse {
    background-color: $color_clubhouse;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/clubhouse_w.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_clubhouse;
      }
    }
  }

  // Email
  &.email {
    background-color: $color_email;

    .icon {
      background-image: url("/assets/landing-preview/assets/icons/s-media/email.svg");
    }
    .text {
      color: $white;
    }

    &.circle {
      background-color: unset;
      .icon {
        background-color: $color_email;
      }
    }
  }

  // Custom link
  &.customLink {
    background-color: $color_customLink;

    .icon {
      border-right: 1px solid black;
      background-image: url("/assets/landing-preview/assets/icons/s-media/link.svg");
    }
    .text {
      color: $white;
    }
  }

  // Custom Link With Label
  &.customLinkWithLabel {
    background-color: $color_customLink;

    .icon {
      border-right: 1px solid black;
      background-image: url("/assets/landing-preview/assets/icons/s-media/customLinkWithLabel.svg");
    }
    .text {
      color: $white;
    }
  }

  &.circle {
    background-color: unset;
    .icon {
      background-color: $color_customLink;
    }
  }
}

// As the border of snapchat icon is different from others only in linear template
.snapchat-border{
  border-right: 1px solid $text_color_grey_mid !important;
}

.youtube-background-size {
 background-size: 75% !important;
}
