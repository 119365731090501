@import "colors";

.card {
  //box-shadow: 0 2px 7px 0 rgba(123, 118, 118, 0.48);
  background-color: $card_background;
  border: 0.5px solid $gray-600;
  border-radius: $border-radius;

  &.transparent {
    background-color: transparent;
    box-shadow: none;
  }

  .card-img-top {
    img {
      width: 100%;
    }
  }
}

.card .card-body {
  font-family: inherit;
  padding: 1rem;
}

.card-title {
  border-bottom: 0.5px solid #d8d8d8;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
}
