.arrow_label {
  .arrow_color {
    color: $gray-300;
  }
}
//scss for mobile view header sorting
.sorting-dropdown {
  .dropdown_list {
    list-style: none;
    padding-left: 20px;
    line-height: 33px;
  }
  .dual-arrow-img {
    width: 12px !important;
    height: 20px !important;
  }

  .dropdown-button {
    width: 100% !important;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      background-color: $white !important;
      color: $primary;
    }
  }
  .dropdown-menu {
    width: 90% !important;
    transform: translate(0px, 42px) !important;
    li{
      &:hover {
        background: $white;
      }
    }
  }
  .dual-arrow {
    height: 14px !important
  }
  .btn.btn-primary-outline:hover .material-icons{
    color: $primary;
  }
  .btn.btn-primary-outline:hover{
    background-color: $white !important;
    color: $primary;
  }
}


/*
 My QR Codes  & Settings> Table Css
*/
.list-table{

  p {
    font-size: $font-size-base !important;
  }

  //label:not(.header_columns) {
  //  font-size: $font-size-base !important;
  //  font-weight: bold;
  //}

  th {
    border-right: 1px solid #dee2e6 !important;
  }
  td {
    border-right: 1px solid #dee2e6 !important;
  }

  .background-header-mobile {
    padding: 0.5rem 0.5rem;
    border-bottom: none;
    background: $gray-300;
  }

  .datatable-header-cell {
    //label {
    //  font-size: 15px !important;
    //}

    input[type=checkbox] {
      width: 18px;
      height: 18px;
    }

    .resize-handle--not-resizable {
      pointer-events: none;
    }
  }

  &.ngx-datatable.material {
    box-shadow: 0 0 0 #0000001f;
    border-radius: 0.5rem;
    margin: 0;
    border: .1px solid rgba(0, 0, 0, 0.2);
  }

  // Line loader inside the table
  .progress-linear {
    position: relative !important;

    .container {
      max-width: 100% !important;
    }
  }

  .empty-row {
    font-size: 1.2rem;
    color: #858282 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: calc(calc(100vh - 360px) / 2) 0 !important;
  }
}

@mixin scroll-list-table($h) {
  &.ngx-datatable .datatable-body {
    height: 100%;
    max-height: calc(100vh - $h) !important;
    overflow: hidden auto;
  }
}

.scroll-list-table-335 {
  @include scroll-list-table(335px);
}

.scroll-list-table-390 {
  @include scroll-list-table(390px);
}

.scroll-list-table-395 {
  @include scroll-list-table(395px);
}

/**
 Bulk Operation tracking table style
 */
.bulk-op-tracking-table {
  .datatable-header-cell {
    .header_columns {
      font-weight: bold;
    }
  }
}


.list-table-row{
  span{
    font-weight: bold;
  }

  &:hover{
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .navigation-allowed{
    cursor: pointer;
  }
}


/**
To make data in center of table
 */
.table th, .table td{
  vertical-align: middle !important;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: white !important;
  box-shadow: inset .5px .5px 40px .5px rgba(0, 0, 0, 0.1) !important;
  transition: all .5s ease;
}

.datatable-row-center{
  border-bottom: 1px solid  rgba(0, 0, 0, 0.2) !important;
  //border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.ngx-datatable .datatable-body .datatable-body-row > div {
  align-items: center;
}

// Remove default transform
.datatable-row-right{
  transform: none!important;
}

//.datatable-checkbox input[type=checkbox]{
//  // Below css are used to remove border styles provided by browser(s)
//  -moz-appearance:none !important;;
//  -webkit-appearance:none !important;
//  -o-appearance:none;
//}

.datatable-checkbox input[type=checkbox]:before{
  border-color: $gray-400 !important;
  width: 17px;
  height: 17px;
}

.datatable-checkbox input[type=checkbox]:after{
  border: 2px solid $gray-400 !important;
  width: 17px;
  height: 17px;
}

.datatable-checkbox input[type=checkbox]:checked:before{
  border-color: $primary !important;
}

.datatable-body-row.datatable-row-even.active,.datatable-body-row.active.datatable-row-odd{
  background: white !important;
  box-shadow: inset .5px .5px 40px .5px rgba(0, 0, 0, 0.1) !important;
  transition: all .5s ease;
}


.ngx-datatable.material .empty-row{
  color: $font-color !important;
}

.datatable-icon-sort-unset::before {
  content: none !important;
  opacity: 0 !important;
}


.table-striped {
  th {
    text-align: center !important;
    color: $font-color !important;
    border-right: 1px solid $gray-500;
  }
}


.mobile-view-my-qr-code{
  .card{
    height: auto !important;
    margin: 3% !important;
  }

  .checkmark{
    left: -10px !important;
  }
  .card-header{
    .direction-down,.direction-up{
      font-size: 34px !important;
      color: $black !important;
    }
  }
}

.ngx-pagination{
  display: table-header-group !important;
  font-size: 14px !important;

  .current {
    background: $gray-600 !important;

    span{
      color: white !important;
    }
  }

  a{
    color: $gray-600 !important;
  }

  .pagination-previous{
    a{
      padding-right: 0;
      color: $gray-600 !important;
      font-size: 14px !important;
    }
  }

  .pagination-next{
    a{
      padding-left: 0;
      color: $gray-600 !important;
      font-size: 14px !important;
    }
  }
}


// Error correction table:
.err-measurement-body > tr > td:first-child{
  border-right: 1px solid #ddd;
}

.err-measurement-body > tr > td{
  padding: 10px 35px 10px 35px;
  font-weight: 500;
}

.err-measurement-head > tr > th{
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

// Override the color of loader > Data table
.ngx-datatable.material .datatable-body .progress-linear .container .bar{
  background-color: white !important;
}


.ngx-datatable.material .datatable-body .progress-linear .container{
  background-color: $primary !important;
}

.datatable-header-inner{
  width: 100% !important;
}

.datatable-scroll{
  display: inherit !important;
}

.datatable-row-center{
  width: 100% !important;
}

.datatable-body-row{
  width: 100% !important;
}

.qr-listViewFilter {
  .active, .chip:hover {
    border: 0.5px rgba(192, 57, 43, 0.3) !important;
    background-color: rgba(192, 57, 43, 0.1) !important;
  }
}

.qr-listViewFilterPopover {
  max-height: 300px;
  overflow: auto;
}

.ngx-datatable.material datatable-header-cell.datatable-header-cell, datatable-header {
  background-color: $lighter !important;
}
