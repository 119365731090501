@import "colors";

.display-block {
  display: block !important;
}
.schedule {
  .date {
    font-size: 1.1em;
  }
  .time {
    font-size: 0.9em;
  }
}
.carousel {
  .carousel-indicators {
    bottom: -5vh;

    li {
      height: 10px;
      width: 10px;
      border-radius: 50%;

      &.active {
        background-color: $gray;
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

/**
Spaces
 */

.w-100px{
  width: 100px;
}

.dropdown:not(.dropdown-hover) .dropdown-menu.show, .dropdown .dropdown-menu {
  margin-top: 8px !important;
}
