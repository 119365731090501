.fi-rs-lock, .fi-rs-info, .fi-rr-lock, .fi-rr-info, .fi-rs-copy-alt, .icon-hover {
  color: #979797;

  &:hover {
    color: $secondary;
  }
}

.fi {
  &::before {
    font-size: 20px;
  }

  &.text-lg {
    &::before {
      font-size: 32px;
    }
  }

  &.text-sm {
    &::before {
      font-size: 16px;
    }
  }

  &.text-xs {
    &::before {
      font-size: 16px;
    }
  }
}
