#overLay {
  position: inherit; /* Sit on top of the page content */
  /* display: none; */
  /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  //height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */

  #overLay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #676767;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}
