.company-branding {
  .content {
    display: flex;
    justify-content: left;
    align-items: center;

    .brand-logo {
      //margin-right: 1rem;

      img {
        //width: 3rem;
        max-height: 2rem;

        &.logo-only {
          max-height: 3rem;
          width: auto !important;
        }
      }
    }
    .brand-title {
      font-weight: 600;
      font-size: 1em;
      margin: 0.25rem;
      max-width: 70%;
    }
  }
}
