
//@import "~bootstrap/scss/bootstrap";

// regular style toast
//@import '~ngx-toastr/toastr';

@import "theme/style";

/**
Global CSS
 */
