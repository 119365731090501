.cuppa-dropdown {
  .selected-list .c-list .c-token{
    background-color: $gray-200;
  }
  .selected-list .c-list .c-token .c-label{
    color: $gray-600;
    word-break: break-all;
  }

  .c-btn{
    color: $primary !important;
  }

  .c-token{
    .c-label{
      color: $primary !important;
    }
  }
}

.list-area{
  width: max-content !important;
}

.qr-type{
  .list-area{
    width: 10rem !important;
  }
}


.filter-category{


  .selected-list:focus,.c-btn:focus{
    outline: none;
  }

  .pure-checkbox{
    label:before{
      display: none !important;
    }

    label{
      padding: 0 !important;
    }

    label:after{
      display: none !important;
    }
  }

  .clear-all{
    display: none !important;
  }
}


.custom-multi-select{
  .selected-list {
    .c-btn{
      font-size: 0.8rem !important;
      padding: 5px 0 5px 5px !important;
    }
  }

  .selected-list:focus, .c-btn:focus {
    outline: none;
  }

  .c-label {
    white-space: nowrap;
  }

  .clear-all {
    right: 28px !important;
  }
  .c-input {
    &:focus {
      border: none;
    }
  }
  .list-filter {
    .c-search {
      top: 5px;
    }
  }
  .selected-list {
    .c-angle-down, .c-angle-up {
      top: 44%;
    }
  }
  .selected-list .c-list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 50px);

    .c-token {
      padding: 4px 0 !important;
    }
    .c-remove{
      display: none;
    }
  }
}

.time-zone-drop-down, .download-data-drop-down, .repeat-event-drop-down{

  .list-area{
    width: inherit !important;
  }

  .single-select-mode .pure-checkbox input[type="checkbox"] + label:before{
    display: none !important;
  }

  .pure-checkbox.select-all{
    display: none !important;
  }

  .clear-all{
    display: none !important;
  }

  .c-btn.disabled{
    background: $white;
  }
}

.download-data-drop-down {
  .selected-list {
    .c-angle-down, .c-angle-up {
      top: 42%;
      width: 22px;
      height: 12px;
    }
  }
  .arrow-up, .arrow-down {
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
  }
  .list-area{
    margin:-14px;
    margin-left: inherit;
  }
}

.selected-list .c-angle-down, .selected-list .c-angle-up {
  top: 40% !important;
}

// Drop down CSS Overrides
// ul contains the lazyContainer class.
.lazyContainer{
  .pure-checkbox{
    padding: 5px !important;
    label{
      color: $gray-600 !important;
    }
  }

  .selected-item {
    background: $white !important;
  }
}

.select-all{
  padding: 5px !important;
  label{
    color: $gray-600 !important;
  }
}

.c-input{
  color: $gray-600 !important;
}

.pure-checkbox input[type="checkbox"]:checked + label:before {
  background-color: $primary !important;
}

.pure-checkbox input[type="checkbox"] + label:before{
  color: $primary !important;
  border: 1px solid $gray-600 !important;
}

.single-select-mode .pure-checkbox input[type="checkbox"]:checked + label:before{
  background-color: $primary !important;
}

.single-select-mode .pure-checkbox input[type="checkbox"] + label:after{
  border-color: white !important;
}


.pure-checkbox{
  .selected-item {
    background: $gray-200 !important;
  }
}
