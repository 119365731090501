/**
Import app scss and custom css here
 */

@font-face {
  font-family: 'bungasairegular';
  src: url('/assets/landing-preview/assets/fonts/Bungasai/bungasai-webfont.woff2') format('woff2'),
  url('/assets/landing-preview/assets/fonts/Bungasai/bungasai-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'copperplateregular';
  src: url('/assets/landing-preview/assets/fonts/Copperplate/Copperplate-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'americanTypeWriterRegular';
  src: url('/assets/landing-preview/assets/fonts/American-type-writer/American-Typewriter-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;  // for avoiding invisible text during font loading
}

@font-face {
  font-family: 'Porsche Next';
  src: url('/assets/landing-preview/assets/fonts/Porsche-next/PorscheNextTT-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

#landing-container {
  //all: initial;
  //* {
  //  all: unset;
  //}
  font-size: 14px;

  @import "global";

  .calendarPopover {
    .customCalendar {
      display: flex !important;
      flex-direction: column !important;
      font-size: 14px !important;

      a {
        @include common-text;
        font-weight: normal !important;
        color: black;
        border-bottom: 1px #dee2e6 solid;
        padding: 15px 40px;
      }

      a:hover {
        background: $background_light;
      }
    }

    .popover {
      left: -15px !important;

      .popover-body {
        padding: 0 !important;
      }
    }
  }

  .flip-y {
    transform: scale(-1, 1);
  }

  /**
    To disable css of app
   */
    .btn-primary {
      span {
        padding: 0;
      }
    }
    img{
      padding: 0;
      border: none;
    }

}

.object-fit-contain {
  object-fit: contain;
}
