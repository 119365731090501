.wedding {
  &.theme-royal {
    .couple-name {
      .first-name, .second-name {
        font-family: 'bungasairegular', sans-serif;
      }
    }
  }

  &.theme-classic {
    .couple-name {
      .first-name, .second-name {
        font-family: 'copperplateregular', sans-serif;
      }
    }
  }

  &.theme-retro {
    .couple-name {
      .first-name, .second-name {
          font-family: 'americanTypeWriterRegular', sans-serif;
      }
    }
  }

  .couple-name {
    .first-name, .second-name {
      font-size: 1.7em;

      > p {
        margin: 0;
      }
    }
  }
}
