// Define vars we'll be using
@use "sass:math";

$brand-success: #5cb85c;
$loader-size: 7em;
$check-height: 2em;
$check-width: 1em;
$check-left: (math.div($loader-size, 6) + math.div($loader-size, 12));
$check-thickness: 3px;
$check-color: $brand-success;

.tick-mark-animation {
  display: none;
  position: relative;

  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: tick-mark-animation;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: 0.75em;
    top: 1.25em;
    position: absolute;
  }
}

@keyframes tick-mark-animation {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
