#custom-scroller-grey {

  &::-webkit-scrollbar {
    width: 0.25em;
    background-color: rgb(216,216,216);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(134,129,129);
    border-radius: 3px;
  }

}
