.popover {
  box-shadow: $popover-box-shadow;
}

// popover title
.popover {
  .popover-header {
    font-weight: 600;
  }
}

.popover-body-p-0 {
  .popover-body {
    padding: 0;
  }
}

.add-tags-popover{
  width: 400px !important;
  max-width: 400px !important;
  z-index: 1;
}

.analytics-export-popover {
  .popover-body{
    padding: 0 !important;
  }
  //
  //width: 100% !important;
  //max-width: 250px !important;

  button {
    border: none !important;
    border-bottom: 1px solid $gray-400 !important;
  }
}

.hash-text-popover {
  max-width: 30rem !important;
}

@media (max-width: 767px) {
  .hash-text-popover {
    max-width: 20rem !important;
  }
}

.no-arrow {
  &.popover {
    .popover-arrow {
      display: none;
    }
  }
}

.popover.lg {
  max-width: 32rem !important;
}

.popover-body {
  .bg-on-hover {
    &:hover {
      background-color: $light;
    }
  }
}
