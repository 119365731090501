/*
 Hide the mat stepper from Background designer component
 Problem : In custom background designer> Step 2 and 3 have been combined, Henceforth change of active stepper will not be visible since 2 and 3 step are written in one component
*/
.bg-designer{
  .mat-horizontal-stepper-header-container{
    display: none;
  }
}

/*
 Stepper
*/
.md-stepper-horizontal {
  display:table;
  width:100%;
  margin:0 auto;
  background-color: $white;

  //.material-symbols-outlined{
  //  color: $white !important;
  //  font-size: 20px !important;
  //
  //  &.primary-color{
  //    color: $primary !important;
  //  }
  //}
  //.material-symbols-outlined .primary-color {
  //}
}

.md-stepper-horizontal .md-step {
  display:table-cell;
  position:relative;
  padding:24px;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
  background-color: $white;
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
  background-color: $gray-300;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display:none;
}
.md-stepper-horizontal .md-step .md-step-circle {
  width:22px;
  height:22px;
  margin:0 auto;
  background-color: $gray-300;
  border-radius: 50%;
  text-align: center;
  line-height:31px;
  font-size: 16px;
  font-weight: 600;
  color: $white;
}

.md-stepper-horizontal.orange .md-step.active .md-step-circle {
  background-color: $primary;
}

.md-stepper-horizontal .md-step.editable .md-step-circle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.md-stepper-horizontal .md-step .md-step-title {
  margin-top:5px;
  font-size:14px;
  font-weight:600;
}

.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}

.md-stepper-horizontal .md-step.active .md-step-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
}

.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
  font-weight:600;
}

.md-stepper-horizontal .md-step .md-step-optional {
  font-size:12px;
}

.md-stepper-horizontal .md-step.active .md-step-optional {
  color:rgba(0,0,0,.54);
}

.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position:absolute;
  top:36px;
  height:1px;
  border-top:5px dashed $gray-300;

  &.completed{
    border-top:5px solid $gray-300 !important;
  }
}

.md-stepper-horizontal .md-step .md-step-bar-left .completed,
.md-stepper-horizontal .md-step .md-step-bar-right .completed {
  position:absolute;
  top:36px;
  height:1px;
  border-top:5px solid $gray-300 !important;
}

.md-stepper-horizontal .md-step .md-step-bar-right {
  right:0;
  left:50%;
  margin-left:20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left:0;
  right:50%;
  margin-right:20px;
}

.md-stepper-horizontal .md-step:first-child .md-step-circle {
  margin: 0 0 0 10%;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-right {
  left: 15%;
}

.md-stepper-horizontal .md-step:last-child .md-step-circle {
  margin: 0 0 0 85%;
}

/*
 Media queries for stepper
*/
// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {

  .md-stepper-horizontal .md-step:first-child .md-step-circle {
    margin: 0 0 0 10%;
  }

  .md-stepper-horizontal .md-step:first-child .md-step-bar-right {
    left: 40% !important;
  }

  .md-stepper-horizontal .md-step:last-child .md-step-circle {
    margin: 0 0 0 80%;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape){
  .md-stepper-horizontal .md-step:first-child .md-step-bar-right{
    left: 25% !important;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait){
  .md-stepper-horizontal .md-step:first-child .md-step-bar-right{
    left: 25% !important;
  }
}

.z-index-2{
  z-index: 2;
}
