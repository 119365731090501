.nav-mobile {
  z-index: 1021;
  background-color: $light;
  //box-shadow: 10px 2px 4px 0 rgba(154, 154, 154, 0.5);

  .menu {
    color: $dark;
    z-index: 1;
  }

  .logo-img {
    height: 34px;
    width: 41%;
  }
}

.g-sidenav-pinned {
  .nav-mobile {
    background-color: $white !important;

    .menu {
      color: $primary;
    }
  }
}

// For space above as Nav-mobile is fixed
@media (max-width: 767.98px) {
  .navbar-vertical {
    margin-top: 43px;
  }
}


////@import "colors";
////@import "mixins";
////@import "space";
//
//#wrapper.toggled {
//  padding-left: 250px;
//  overflow: hidden;
//}
//
//#sidebar-wrapper{
//  @include slow-transition;
//
//  z-index: 998;
//  left: 250px;
//  width: 0;
//  height: 100%;
//  margin-left: -250px;
//  background: $primary;
//  position: fixed;
//}
//
//#wrapper.toggled #sidebar-wrapper {
//  width: 250px;
//}
//
///* Sidebar Styles */
//.sidebar-nav {
//  @include slow-transition;
//  width: 250px;
//  padding: 0;
//  list-style: none;
//  margin: 2px 0 0;
//
//  .is-active{
//    background: $primary_active !important;
//    //margin: 0 3%;
//  }
//
//  li{
//    margin: 0 4%;
//    text-indent: 7px;
//    line-height: 41px;
//
//    a {
//      display: block;
//      text-decoration: none;
//      color: $primary_contrast;
//      border-radius: 2px;
//
//      &:hover {
//        text-decoration: none;
//        color: $white;
//        background: $primary_focus;
//      }
//
//      &.navbar-brand {
//        padding: unset;
//
//        &:focus {
//          outline: none;
//        }
//        &:hover {
//          background: none;
//        }
//      }
//
//    }
//  }
//
//  a, p {
//    font-size: 14px;
//    color: $primary_contrast; // removed !important
//    line-height: 15px;
//  }
//
//  label{
//    font-size: 14px;
//    color: $primary_contrast; // removed !important
//  }
//
//  .notification{
//    display: block;
//    line-height: 1.3;
//    opacity: 0.9;
//    border: 1px solid $border-light;
//
//    a {
//      color: $primary_contrast;
//    }
//  }
//}
//
//
//.sidebar-open{
//
//  #sidebar-wrapper{
//    width: 250px !important;
//  }
//  .submenu {
//    display: contents;
//  }
//
//  .toggleBtn{
//    @include slow-transition;
//    padding-left: 211px;
//    position: fixed;
//  }
//
//  .sidebar-info{
//    color: #f1a4a4;
//    display: block !important;
//    a{
//      font-size: 12px !important;
//      display: contents;
//    }
//    span{
//      font-size: 10px !important;
//    }
//    div{
//      line-height: 15px !important;
//    }
//    &.bottom{
//      border-top: 1px solid $border-light;
//    }
//  }
//  //Change color of navbar header when sidebar is open
//  .nav-mobile{
//    position: fixed;
//    width: 100%;
//    z-index: 999;
//    .menu{
//      color: $primary !important;
//    }
//    .search{
//      color: $gray !important;
//    }
//    background-color: $primary_contrast !important;
//    box-shadow: 10px 2px 4px 0 rgba(154, 154, 154, 0.5);
//  }
//}
//
////Parent Class | Used for managing sidebar-close UI
//.sidebar-close{
//  // This is for notification center ie NC will stick to the sidebar
//  @media (min-width: 600px) {
//    #notificationCenter {
//      position: absolute !important;
//      left: 57px !important;
//      top: -376px !important;
//    }
//  }
//  //  Set width to 80px when Sidebar is closed for Desktop View.
//  #sidebar-wrapper{
//    width: 80px !important;
//  }
//  .submenu, .sidebar-info{
//    display: none;
//  }
//  .sidebar-nav{
//    -webkit-transform: translate3d(0px, 0px, 0px);
//    transform: translate3d(0px, 0px, 0px);
//    visibility: visible;
//
//    -webkit-transition: width 0.3s;
//    transition: width 0.3s;
//
//    width: 80px !important;
//    span,p,label, .announcekit-widget-badge{
//      display: none !important;
//    }
//    li, li a {
//      width: 75px !important;
//    }
//  }
//
//  //Change color of navbar header when sidebar is closed
//  .nav-mobile{
//    background-color: $primary !important;
//    box-shadow: 10px 2px 4px 0 rgba(154, 154, 154, 0.5);
//    .menu{
//      color: $primary_contrast !important;
//    }
//    .search{
//      color: $primary_contrast !important;
//    }
//  }
//  //Handling Sidebar for Mobile View | Small and Extra small devices
//  @media (min-width: 576px) and (max-width: 767.98px)  {
//    #sidebar-wrapper {
//      width: 0 !important;
//      li, li a {
//        width: 0 !important;
//      }
//    }
//  }
//
//  @media (max-width: 575.98px) {
//    #sidebar-wrapper * {
//      width: 0 !important;
//      li, li a {
//        width: 0 !important;
//      }
//      i{
//        display: none !important;
//      }
//    }
//  }
//
//  .toggleBtn {
//    @include slow-transition;
//    position: fixed;
//    padding-left: 44px;
//  }
//}
//
//// Material Icons for Sidebar.
//.sidebar-close, .sidebar-open {
//  .material-icons {
//    color: $primary_contrast !important;
//    font-size: 25px !important;
//    padding: 6px 16px 6px 0;
//    display: inline-flex !important;
//    vertical-align: middle !important;
//
//    &.toggle-icon{
//      font-size: 24px !important;
//    }
//  }
//  .img-sidebar{
//    height: 19px;
//    width: 19px;
//    margin-left: 10px;
//    margin-right: 19px;
//  }
//}
//
//.main-content{
//  z-index: -1;
//}
//.collapsed-container{
//    width: calc(100% - 80px) !important;
//    float: right !important;
//}
//
//.expand-container{
//    width: calc(100% - 250px) !important;
//    float: right !important;
//}
//
////Handling Sidebar for Mobile View | Small and Extra small devices
//@media (min-width: 576px) and (max-width: 767.98px)  {
//
//  .collapsed-container{
//    width: 100% !important;
//    float: right !important;
//  }
//  .expand-container{
//    width: calc(100%) !important;
//    float: right !important;
//  }
//  .sidebar-close {
//    #sidebar-wrapper {
//      left: 0;
//    }
//  }
//}
//
//@media (max-width: 575.98px) {
//
//  .collapsed-container{
//    width: 100% !important;
//    float: right !important;
//  }
//  .expand-container{
//    width: calc(100%) !important;
//    float: right !important;
//  }
//  .sidebar-close {
//    #sidebar-wrapper {
//      left: 0;
//    }
//  }
//}
//
//
//@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
//  .sidebar-close,.sidebar-open{
//    z-index: 999;
//  }
//  .sidebar-close{
//    .sidebar-nav{
//      width: 0 !important;
//    }
//  }
//}
//// For space above as Nav-mobile is fixed
//@media (max-width: 767.98px) {
//  .upper-nav{
//    margin-top: 54px !important;
//  }
//}
//
//.side-bar-bottom-nav{
//  //overflow: hidden auto;
//
//  .material-icons{
//    color: #e9b9b4 !important;
//  }
//
//  :not([btn-sign-up]){
//    color: #e9b9b4;
//  }
//}
//
//.bottom-nav{
//  border-top: 1px solid $border-light;
//}
//
//
///**
//For custom scroll
// */
//
//@media (min-height: 600px) {
//  //.bottom-nav{
//  //  overflow: hidden auto;
//  //}
//
//  .bottom-nav::-webkit-scrollbar-track {
//    display: block;
//    background: transparent;
//    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
//    border-radius: 10px;
//  }
//
//  .bottom-nav::-webkit-scrollbar {
//    display: block;
//    width: 8px;
//  }
//
//  .bottom-nav::-webkit-scrollbar-thumb {
//    display: block;
//    background: rgba(255, 255, 255, 0.45);
//    border-radius: 10px;
//    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
//  }
//}
//
//@media (max-height: 601px) {
//
//  #sidebar-wrapper{
//    overflow: hidden auto;
//  }
//
//  #sidebar-wrapper::-webkit-scrollbar-track {
//    display: block;
//    background: transparent;
//    border-radius: 10px;
//  }
//
//  #sidebar-wrapper::-webkit-scrollbar {
//    display: block;
//    width: 8px;
//  }
//
//  #sidebar-wrapper::-webkit-scrollbar-thumb {
//    display: block;
//    background: rgba(255, 255, 255, 0.45);
//    border-radius: 10px;
//    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
//  }
//}
//
//// To give some margin on top of scroll bar
//@media(min-width: 767.98px){
//  #sidebar-wrapper::-webkit-scrollbar-track {
//    margin-top: 90px;
//  }
//}
