//.form-control {
//  border: none;
//
//  &.is-invalid {
//    border: $input-border-width solid $input-border-color;
//    padding: $form-control-outline-padding;
//    line-height: 1.3 !important;
//
//    &:focus {
//      box-shadow: 0 0 0 2px rgba($form-feedback-invalid-color, .6);
//    }
//  }
//
//  &.is-valid {
//    border: $input-border-width solid $input-border-color;
//    padding: $form-control-outline-padding;
//    line-height: 1.3 !important;
//
//    &:focus {
//      box-shadow: 0 0 0 2px rgba($form-feedback-valid-color, .65);
//    }
//  }
//
//  &[disabled] {
//    padding: $form-control-outline-padding;
//    line-height: 1.45 !important;
//  }
//}
//
//.input-group {
//  .input-group-text {
//    position: absolute;
//    padding: .75rem 0;
//    right: 0;
//    border-right: 0 !important;
//    top: 50%;
//    -ms-transform: translateY(-50%);
//    transform: translateY(-50%);
//    z-index: 99;
//
//    i {
//      color: $gray-600;
//    }
//  }
//
//  &.input-group-static {
//    .input-group-text {
//      bottom: 0;
//    }
//  }
//}

label.required {
  &::after {
    content: '*';
    color: $primary;
    //color: $body-color;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.passwordEye {
  border-radius: $border-radius-md;
  border: $input-btn-border-width solid $border-color;
  border-left: 0;
  transition: 0.2s ease;
}

// Do not remove below code
//.phoneNumberField {
//  border-radius: $border-radius-md;
//
//  .countryCodeInput {
//    border-radius: $border-radius-md;
//  }
//
//  .phoneSeparatorLine {
//    border-right: $input-btn-border-width solid $border-color;
//  }
//
//  &:hover {
//    border-color: $secondary;
//  }
//}

.input-component, .calendar-component {
  .input-group {
    border: $input-btn-border-width solid $border-color;
    border-radius: $border-radius-md;
    &:hover {
      border-color: $secondary;
    }

    input, .input-group-text, .passwordEye, .form-control {
      border: 0;
    }
  }

  .ng-select-container {
    //background-color: $lighter !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback)
{
  margin-left: 0;
}
