@import "colors";

.font-weight-500 {
  font-weight: 500 !important;
}

.help-text {
  @include help-text;
}

.common-text {
  @include common-text;
}

.common-text-primary-color {
  @include common-text;
  color: $primary !important;
}

.linked-help-text {
  @include linked-help-text;
}

.underline{
 text-decoration: underline;
}

.underline-primary {
  text-decoration: underline !important;
  text-decoration-color: $primary !important;
}

/**
Font Sizes
 */
.fs-12{
  font-size: 12px !important;
}

.fs-14{
  font-size: 14px !important;
}

.fs-16{
  font-size: 16px !important;
}

.fs-18{
  font-size: 18px !important;
}

.fs-20{
  font-size: 20px !important;
}

.fs-22{
  font-size: 22px !important;
}

.fs-28{
  font-size: 28px !important;
}

.fs-30{
  font-size: 30px !important;
}

.fs-36{
  font-size: 36px !important;
}

.fs-64{
  font-size: 64px !important;
}
