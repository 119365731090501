.form-check:not(.form-switch) .form-check-input {
  float: initial !important;
  //margin-left: auto !important;

  &[type="checkbox"],
  &[type="radio"] {
    border: 2px solid $secondary;
    margin-top: 0; // $form-text-margin-top
    position: relative;

    &:checked {
      border-color: $secondary;
    }
  }

  &[type="checkbox"] {
    //background-image: none;
    &:after {
      transition: opacity $form-check-transition-time ease-in-out;
      //font-family: "FontAwesome";
      //content: "\2713";
      width: 100%;
      height: 100%;
      color: $form-check-input-checked-color;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      //font-size: $font-size-sm - .205;
      font-size: 1rem;
      opacity: 0;
    }

    &:checked{
      background-color: $secondary;
      &:after {
        opacity: 1;
      }
    }
  }

  &[type="radio"] {
    transition: border 0s;
    background: transparent;

    &:after {
      transition: opacity $form-check-transition-time ease-in-out;
      content: "";
      position: absolute;
      width: $form-check-radio-after-width;
      height: $form-check-radio-after-width;
      border-radius: 50%;
      //background-image: escape-svg($form-check-radio-checked-bg-image), var(--#{$variable-prefix}gradient);
      background: $secondary;
      opacity: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:checked {
      padding: 6px;
    }

    &:checked:after {
      opacity: 1;
    }

    &:active{
      box-shadow: $form-check-radio-after-shadow;
      border-radius: $border-radius-pill;
      transition: $form-check-radio-transition;
    }
  }
}

.form-check-label,
.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.form-check-label{
  font-size:$font-size-base;
  font-weight: $font-weight-normal;
}

.form-check-input{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/**
Tree Checkbox SCSS
 */
.mat-checkbox-tree {

  max-height: 400px;
  overflow-y: auto;

  .mat-checkbox-checked, .mat-checkbox-indeterminate {
    &.mat-accent {
      .mat-checkbox-background {
        background-color: $primary;
      }
    }
  }

  .mat-tree-node {
    min-height: 28px !important;
  }

  @media (max-width: 650px) {
    .mat-checkbox-label {
      white-space: normal;
      font-size: 12px;
      line-height: 17px;
    }
  }

  .tree-node {
    .mat-checkbox-label {
      //font-weight: bold !important;
    }
    label {
      margin-bottom: 0;
    }
  }

  .expand-collapse-icons {
    margin-right: 0.25rem;
    color: white;
    background-color: #979797;
  }

  .tree-node-child {
    clear: both;
    display: block;
    background: url("/assets/gifs/dashed-line-vertical.gif") repeat-y scroll left top rgba(0, 0, 0, 0);
    margin-left: -11px;
  }

  .tree-node-child label {
    background: url("/assets/gifs/dashed-line-horizontal.gif") no-repeat scroll 3px 12px rgba(0, 0, 0, 0);
    font-weight: normal;
    display: block;
    padding-left: 15px;
  }

  .mat-checkbox-inner-container {
    .mat-checkbox-frame {
      border-color: #121314;
    }
  }
}
