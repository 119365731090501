/*
 Settings> Tags table css
*/
.settings-tags-table {

  .datatable-body-cell:nth-child(n+3) {
    text-align: center !important;
  }

  .datatable-header-cell{
    font-size: 14px !important;
  }

  .datatable-header-cell:not(:first-child):not(:last-child) {
    border-right: 2px solid $gray-400;
  }

  &.ngx-datatable.material {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .datatable-row-center {
    border-top: 0 solid !important;
  }

  .datatable-header {
    background-color: $gray-200;
  }
}
