.custom-component {
  .heading {
    border-radius: $border-radius-md;
    background-color: $lighter;
    border: 0.5px solid $lighter;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 15px;
    font-weight: bold;
    font-size: $font-size-base;

    span:not(.fi), i:not(.fi) {
      //@include desc-heading;
      text-align: center;
      display: inline-block;
      margin: 10px 5px;
      padding: 5px;
      //color: $text_color_darker;
      @media screen and (max-width: 767px) {
        margin: 7px;
        padding: 3px;
        text-align: left;
      }
    }

    &.border-on-hover:hover {
      border: 0.5px solid $secondary;
    }
  }

  .content-box-spacing {
    padding: 0 1rem;
    margin: 0 0.25rem;
  }

  .content-headline-spacing {
    padding: 0.5rem 1.25rem;
  }

    //i{
    //  float: right;
    //  padding-top: 0.5%;
    //  font-size: 28px !important;
    //  //color: $material_icon_mid !important;
    //}
    //.rsvpInfo {
    //  font-size: 18px !important;
    //}
    //
    //i:hover{
    //  color: $primary !important;
    //}
}

.enter-content-container {
  .background-header {
    padding: 0.5rem 1rem;
    background: $lighter;
    font-size: $font-size-base;
  }

  //
  //.highlighted-heading {
  //  border-radius: 2px;
  //  background-color: $primary;
  //
  //  span,i {
  //    //@include desc-heading;
  //    text-align: center;
  //    display: inline-block;
  //    margin: 10px 10px;
  //    padding: 5px;
  //    color : white;
  //  }
  //  i{
  //    float: right;
  //    padding-right: 5%;
  //    padding-top: 0.5%;
  //    font-size: 28px;
  //    //color: $material_icon_mid !important;
  //  }
  //
  //  i:hover{
  //    color: $primary !important;
  //  }
  //}
  //
  //
  //.block {
  //  border: solid 1px #d8d8d8;
  //
  //  span:not(.CodeMirror *){
  //    //@include desc-heading;
  //    font-size: 14px !important;
  //  }
  //
  //  .show-ranges{
  //    span{
  //      font-weight: normal !important;
  //    }
  //
  //  }
  //
  //  p{
  //    //@include help-text;
  //  }
  //
  //  label{
  //    //@include text-label;
  //  }
  //}
  //
  .deleted-component-icon {
    @media screen and (min-width: 767px) {
      button {
        height: 53px;
      }
      i {
        font-size: $h5-font-size !important;
      }
    }
  }
  //
  //.component-wrapper{
  //  padding-left: 0 !important;
  //  ul{
  //    list-style-type: none;
  //  }
  //}
  //
  //
  ///*
  //* Banner Image
  //*/
  //.add-banner-img > div{
  //  margin: 2%;
  //
  //  .material-icons{
  //    font-size: 64px !important;
  //    display: flex;
  //    justify-content: center;
  //    padding: 30% 0;
  //
  //  }
  //
  //  .add-banner-img >  div:hover{
  //    border: solid 1px #c0392b !important;
  //  }
  //}

  .enter-content-side-img {
    .landing-preview {
      margin: 0 auto;
      max-width: 370px;

      .mobile-preview {
        width: 80%;
        margin: 0 auto;
        height: 37rem;
      }

      @media only screen and (max-device-width: 480px) {
        max-width: 300px;
        .mobile-preview {
          width: 90%;
          height: 30rem;
        }
      }
    }
  }
}


