$g_map_color: #6091EE;

.business-card {
  .btn-add-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 100%;
    color: white;
  }
  .button-map {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 0.5px solid #D8D8D8;
    background: #FAFAFA;
    font-size: 0.75rem;
    width: 100%;
    .map-icon {
      display: block;
      background-image: url("/assets/landing-preview/assets/icons/google_map.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      font-size: 0;
      height: 24px;
      width: 24px;
    }
  }
}

#template1, #template2, #template3, #template4, #template5, #template6, #template7 {
  min-height: 100%;
}
