.page-title {
  .item {
    font-size: 1.4em;
    font-weight: 500;
    width: 100% !important;
  }
  p {
    margin-bottom: 0;
  }

  span, strong, s, em, u {
    font-family: inherit;
  }
}
