//
// Breadcrumb
//

.breadcrumb-item {
    font-size: $font-size-sm;
    &.text-white{
      &::before {
          color: $white;
      }
    }

}

.breadcrumb-dark {
    background-color: $breadcrumb-dark-bg;

    .breadcrumb-item {
        font-weight: 600;

        a {
            color: $breadcrumb-dark-color;

            &:hover {
                color: $breadcrumb-dark-hover-color;
            }
        }

        + .breadcrumb-item {
            &::before {
                color: $breadcrumb-dark-divider-color;
            }
        }

        &.active {
            color: $breadcrumb-dark-active-color;
        }
    }
}


// Links

.breadcrumb-links {
    padding: 0;
    margin: 0;
    background: transparent;
}



.breadcrumb {
  background-color:  $gray-200;
  padding: 0;
  display: -webkit-inline-flex;
  width: 100%;
  height: 56px;
}

.breadcrumb-arrow li, .breadcrumb-arrow li a, .breadcrumb-arrow li span {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  font-size: $font-size-xl;
  //@include fluid-type(14px, 18px,768px,1200px);
  padding : 15px 0;
}

.breadcrumb-arrow li:not(:first-child) {
  margin-left: -5px
}
.breadcrumb-arrow li+li:before {
  padding: 0;
  content: "";
}

.breadcrumb-arrow li a, .breadcrumb-arrow li:not(:first-child) span {
  height: 36px;
  padding: 0 50px;
}

.breadcrumb-arrow li:first-child a {
  padding: 0 10px;
}
.breadcrumb-arrow li a {
  position: relative;
  color: $gray-400;
  text-decoration: none;
}

.arrow_box {
  position: relative;
  width: 33%;
}
.arrow_box:after, .arrow_box:before {
  left: 104%;
  border: solid transparent;
  content: "";
  position: absolute;
  pointer-events: none;
  display: inline-block;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: $gray-200;
  border-width: 25px;
  margin-top: -30px;
  top: 48%;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: $gray-300;
  border-width: 29px;
  margin-top: -26px;
  top: 36%;
}


ul#breadcrumb li:last-child:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: $gray-200;
}


ul#breadcrumb li:last-child:before {;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: $gray-200;
}

/*
* Change the color of breadcrumb on hovering
*/
.breadcrumb-arrow :hover{
  a{
    color: $gray-400;
  }
}

.breadcrumb{
  .active{
    a{
      color: $primary;
    }
  }
}



/**
Create QR Code Steps Breadcrumb
 */
.stepNav {
  margin: 30px 20px;
  padding-right: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  gap: 30px;
  list-style: none;

  .content-text {
    margin-top: 1px;
    z-index: 3;
  }

  .inner {
    flex: 1;
    position: relative;

    &:first-child {
      border-left: 1px solid #EEEEEE;
      a:before {
        display: none !important;
      }
    }
  }

  a {
    width: 100%;
    height: 38px;
    //padding: 0 0 0 25px;
    color: #717171;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #EEEEEE;
    border-left: none;
    text-decoration: none;
    border-right: 0;
    background-color: #EEEEEE;
    float: left;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:before {
      content: "";
      width: 26.5px;
      height: 27px;
      border-left: 1px solid #EEEEEE;
      border-bottom: 1px solid #EEEEEE;
      display: block;
      position: absolute;
      top: 4.5px;
      left: -13px;
      transform: rotate(-135deg);
      background: white;
    }

    &:after {
      content: "";
      width: 26.5px;
      height: 26.5px;
      border-right: 1px solid #EEEEEE;
      border-bottom: 1px solid #EEEEEE;
      display: block;
      position: absolute;
      top: 4.8px;
      right: -13.6px;
      z-index: 2;
      background: #EEEEEE;
      transform: rotate(-45deg);
    }
  }

  .active {
    a {
      color: white;
      background-color: $primary;
      border-color: $primary;

      &:before {
        border-color: $primary;
      }

      &:after {
        background: $primary;
        border-color: $primary;
      }
    }
    span {
      color: $white;
    }
  }

  .post-active {
    a {
      border: 0.5px solid #97979780;
      color: $primary;
      &:after {
        border-right: 0.5px solid #97979780;
        border-bottom: 0.5px solid #97979780;
      }
      &:before {
        border-left: 0.5px solid #97979780;
        border-bottom: 0.5px solid #97979780;
      }
    }
  }
}

