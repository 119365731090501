div.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  img {
    //width: 100%;
    max-width: 125px;
  }
}

.button-spinner{
  position: absolute;
  right: 36px;
  top: 23px;
}
