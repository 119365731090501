$primary: #c0392b;
//$primary: #f9f9f9;
//$primary_focus : #d74637;
$primary_focus : #f9f9f9;
$gray: #585858;
$secondary: #121314;
$primary_contrast : #fffdfd;
$white : #ffffff;
$gray_light: #808080ab;
$gray-600: #979797;

/*
   Text Color Codes
 */
$text_color_grey_mid : #8e8686;
$text_color_grey_light : #a9a9a9;
$text_color_grey_dark : #797372;
$text_color_dark : #6a6a6a;
$text_color_darker : #615757;

/*
   Material Icons Color Codes
 */
$material_icon : #b4abab;

/*
  Background color codes
*/
$background: #D8D8D8;
$background_light : #f8f8f8;
$background_dark : #A9A9A9;
$background_mid : #f0f0f0;


$card_background: #f9f9f9;

$border-radius: 6px !default;
$border-radius-sm: 4px !default;
