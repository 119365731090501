.description-content{
  p {
    margin-bottom: 0;
  }
  img{
    max-width: 100%;
    height: auto;
  }

  span, strong, s, em, u {
    font-family: inherit;
    color: inherit;
  }
}
