.password-eye {
  color: #c8c8c8;
  font-size: 23px;
  background-color: white;
  position: absolute;
  margin-right: 5px;
}

.plus-icon{
  font-size: 25px;
}
