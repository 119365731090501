@import "../colors";

.btn-store {
  //color: $gray;
  color: #fff;
  min-width: 254px;
  padding: 12px 20px;
  //border-color: #dddddd;
  border-color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //background-color: $white;
  background-color: #000;

  &:focus, &:hover {
    //color: $white;
    //background-color: $gray;
    //border-color: $gray;
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }

  .btn-label, .btn-caption {
    display: block;
    text-align: left;
    line-height: 1;
  }

  .btn-caption {
    font-size: 24px;
  }
}
