.mat-chip-list {
  &:focus {
    outline: none;
  }
}

.mat-form-field-wrapper {
  margin-left: 15px !important;
  padding-top: 5px !important;
  padding-bottom: 12px !important;
}

.tag-chip-list {
  width: 100%;
  border: 1px solid $gray-400 !important;
  border-radius: 0.25rem;
  border-left-style: none;
}

.mat-form-field-infix {
  padding: 0 !important;
  margin: 0 !important;
  border-top: 0.14375em solid transparent !important;

  .mat-autocomplete-trigger{
    border: white !important;
  }
}

.mat-form-field-underline {
  display: none !important;
}

.data-table-tag-chip-list{
  padding-bottom: 0;
  border: none !important;

  .popover-input-tag {
    height: 24px;
    margin: 2%;
    border: 1px solid #f0f0f0 !important;
  }

  .mat-form-field-wrapper {
    margin-left: 0 !important;
  }

  .datatable-header-cell-template-wrap {
    text-align: center !important;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: center !important;
  }

  .mat-chip-list-wrapper .mat-standard-chip, .mat-chip-list-wrapper input.mat-input-element {
    caret-color: #615757 !important;
  }

}
