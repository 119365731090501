/* You can add global styles to this file, and also import other style files */
//@import "@ng-select/ng-select/themes/ant.design.theme.css";
//@import "@ng-select/ng-select/themes/material.theme.css";
@import "@ng-select/ng-select/themes/default.theme.css";

// NgSelect customize SCSS

//.ng-select {
//  .ng-arrow-wrapper {
//    transition: transform 0.01s, -webkit-transform 0.01s;
//
//    .ng-arrow {
//      width: 6px !important;
//      height: 6px !important;
//      border-width: 2px 2px 0 0
//    }
//  }
//  .ng-select-container {
//    border: 1px solid #c9c9c9 !important;
//    min-height: 37.99px !important;
//    .ng-value-container .ng-input>input{
//      height: 100% !important;
//      padding-top: 0.4rem;
//    }
//  }
//}
//.ng-select.ng-select-focused .ng-select-container {
//  border: 1px solid #ced4da !important;
//}
//
//.ng-select.ng-select-opened>.ng-select-container {
//  box-shadow: none !important;
//  border: 1px solid #ced4da !important;
//}

//.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
//  background-color: rgba(88, 88, 88, 0.05) !important;
//}

//.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//  border-bottom: 1px solid #ced4da !important;
//  padding: 10px;
//}

//.timeZonedropdown {
//  .ng-select.ng-select-container {
//    border-left: 0px !important;
//    border-radius: 2px 4px 4px 0px !important;
//  }
//}


// Pre Loading
.lds-ring {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  margin: 8px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7c7f81 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tab-content > .tab-pane {
  display: block !important;
}
