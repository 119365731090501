@import "../colors";

.profile-info {
  .content {
    display: flex;
    justify-content: left;
    align-items: center;

    .avatar-logo {
      //margin-right: 1rem;

      img {
        width: 4rem;
        //width: 6rem;
      }
    }
    .profile-detail {
      font-weight: 600;
      font-size: 1.1em;

      .name {
        font-size: 0.9em;
        //font-size: 1.3em;
        font-weight: bold;
      }

      .title, .company {
        font-size: 0.7em;
        //font-size: 0.9em;
      }
    }
  }
}



.contact-details {
  .title {
    color: $text_color_darker;
  }

  .item, address {
    font-size: 0.9em;
    color: $text_color_dark;

    a {
      color: $text_color_dark;
    }
  }
}
