@import "variables";

.toast-custom {
  background-color: transparent !important;
  box-shadow: none !important;
  pointer-events: all;
}

.toast-container {
  .toast-close-button {
    span {
      color: $white;
    }
  }
}
