$dropup-animation-arrow-bottom-position: 13px;
$dropdown-animation-arrow-right-position: 128px;

.dropup {
  .dropdown-menu {
    box-shadow: $dropdown-box-shadow;
    transition: $dropdown-transition;
    cursor: pointer;
    top: auto !important;
    bottom: 100% !important;
    margin-bottom: $dropup-mb !important;
    display: block;
    opacity: 0;
    transform-origin: $dropup-transform-origin;
    pointer-events: none;
    transform: $dropup-transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform,box-shadow;

    &.show{
      pointer-events: auto;
      transform: $dropup-transform-show;
      opacity: 1;

      &:after {
        bottom: -($dropup-animation-arrow-bottom-position - 2);
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: $dropup-animation-arrow-bottom-position;
      left: $dropdown-animation-arrow-left-position;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent $white transparent;
      transition: $dropup-animation-arrow-transition;
      rotate: 180deg;
    }
  }

  .dropup-top-end {
    &:after {
      left: $dropdown-animation-arrow-right-position;
    }
  }
}
