@import "colors";

.form-control {
  border: 0.5px solid $gray-600;
  border-radius: $border-radius;
  &::placeholder {
    color: #48484866;
  }
}

.custom-control {

  &.custom-checkbox {
    .custom-control-label::before {
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 0.1rem;
    }
    .custom-control-label::after {
      width: 1.3rem;
      height: 1.3rem;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("/assets/landing-preview/assets/icons/checked.svg");
    }
  }

  &.custom-radio {
    .custom-control-label::before {
      width: 1.3rem;
      height: 1.3rem;
    }
    .custom-control-label::after {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  .custom-control-label {
    padding-left: 0.5rem;
    line-height: 1.8rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $primary;
    background-color: $primary;
  }
}

.input-group {
  &.icon-left {
    input {
      border-left: none;
      padding-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  .input-group-text {
    background-color: white;
    border: 0.5px solid $gray-600;
    border-radius: $border-radius;
  }
}
