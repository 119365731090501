.datepicker {
  &.form-control {
    border: 1px solid $border-color;
    background-color: $white;
    padding: .5rem .5rem;
  }
}

.md-drppicker.double{
  min-width: max-content;
}

.md-drppicker td.active, .md-drppicker td.active:hover{
  background-color: $gray-300 !important;
  color: $primary !important;

}

.md-drppicker .btn{
  background-color: $primary !important;
  margin: 2%;
  padding: 0 4% !important;
  border-radius: $btn-border-radius !important;
}

.md-drppicker .ranges ul li button.active{
  background-color: $primary !important;
}
