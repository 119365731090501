.cke_textarea_inline{
  border: 1px solid #c8c8c8 !important;
  height: 42px !important;
}

.cke_focus{
  border: 1px solid $input-border-color !important;
  outline: $input-border-color !important;
}

.cke_focus:focus, .cke_focus{
  border: 1px solid $input-border-color !important;
  outline: $input-border-color !important;
}

.cke_editable_themed,.cke_textarea_inline{
  p{
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: black !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
  }
}

.cke_reset, .cke_textarea_inline{
  border-radius: 0.25rem !important;
}

#ckEditor-component {
  span, strong, s, em, u {
    font-family: inherit;
    color: inherit;
  }
}
