ngb-modal-backdrop {
  z-index: $zindex-modal !important;
}

.templateDesignModal {
  @media (min-width: 1200px) {
    .modal-dialog {
      max-width: 60% !important;
    }
  }
}
