@font-face {
  font-family: 'Arial';
  src: url('/assets/fonts/arial/arial.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brush Script MT';
  src: url('/assets/fonts/Brush-script-mt/brushscriptMT.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('/assets/fonts/Noto-sans/NotoSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('/assets/fonts/Times-new-roman/times-new-roman.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway/Raleway-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bungasai';
  src: url('/assets/fonts/Bungasai/Bungasai.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate';
  src: url('/assets/fonts/Copperplate/Copperplate-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'American Typewriter';
  src: url('/assets/fonts/American-type-writer/American-Typewriter-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Porsche Next';
  src: url('/assets/fonts/Porsche-next/PorscheNextTT-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Porsche Next Thin';
  src: url('/assets/fonts/Porsche-next/PorscheNextTT-Thin.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Porsche Next Bold';
  src: url('/assets/fonts/Porsche-next/PorscheNextTT-Bold.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
