.carousel {
  .carousel-indicators {
    &:focus {
      outline: none !important;
    }
  }

  .picsum-img-wrapper {
    height: 18vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;

    img {
      width: auto !important;
      max-width: 100%;
      max-height: 18vh;
    }
  }
}
ngb-carousel.carousel.slide{
  outline: none !important;
}
