.modal-backdrop-1 {
  background-color: #ebebeb;
  opacity: 1 !important;
}
.modal-transparent-bg-no-border {
  .modal-content {
    background-color: transparent;
    border: none;
  }
}
