.static-dynamic-comparision-table{
  table {
    table-layout: fixed !important;
    width: 100% !important;

    font-size: 12px !important;

    tr > td {
      padding: 10px !important;
    }

    td:first-child {
      text-align: left;
      width: 100%;
    }

    //.material-symbols-outlined{
    //  font-size: 24px !important;
    //
    //  &.cross{
    //    color: $gray-700 !important;
    //  }
    //
    //  &.check{
    //    color: $primary !important;
    //  }
    //
    //  td, th {
    //    white-space: nowrap;
    //    overflow: hidden;
    //  }
    //
    //}
  }
}

/**
 * Horizontal line with label
 */
.horizontal-line-label {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $gray-500;
  line-height: 0.1em;
  margin: 10px auto 0;
  color: $font-color;

  span {
    background: #fff;
    padding: 0 10px;
  }
}

/**
Used for input type number arrows
 */
.input-number-arrow {
  input[type=number]::-webkit-inner-spin-button {
    cursor:pointer;
    right: -12px;
    height: 20px !important;
    padding: 11px;
    position: relative;
    opacity: 1;
    width: 14px;
  }
}

.error-text {
  color: $primary !important;
}

@media screen and (min-width: 768px) {
  .static-dynamic-comparision-table {
    max-width: 40% !important;
  }
}
