.row.design-border {
  display: block;

  hr {
    background-size: contain;
    display: block;
    height: 18px;
    border: 0;
    position: relative;

    &.designer-line-1 {
      background: url("/assets/landing-preview/assets/images/design/border_.svg") no-repeat top center;
    }

    &.designer-flower-1 {
      background: url("/assets/landing-preview/assets/images/design/flower_1.svg") no-repeat top center;
      height: 25px;
    }

    &.designer-flower-2 {
      background: url("/assets/landing-preview/assets/images/design/flower_2.svg") no-repeat top center;
      height: 25px;
    }
  }
}

