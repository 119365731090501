/**
 Media queries for custom logo design(s)
 */

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .custom-logo-media-mgmt {
    .custom-component {
      .heading {
        .col-md-9 {
          width: 60% !important;
          flex: 0 0 60% !important;
        }
        .col-md-2 {
          width: 40% !important;
          flex: 0 0 40% !important;
          max-width: 30% !important;
        }
      }
    }

    .media-template-view{
      width: 50% !important;
      max-width: 50% !important;
      flex: 0 0 50% !important;
    }

  }

  .data-pattern-color .gradient{
    width: 4rem !important;
    height: 4rem !important;
  }

  .data-pattern-color .gradient{
    margin: 0 0.2rem !important;
  }
}

/********************************************************************************
// Media Queries(s)
********************************************************************************/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
  // QR Code Preview
  .qr-details-canvas {
    .canvas-container{
      width: 180px !important;
      height: 180px !important;
      canvas{
        width: 180px !important;
        height: 180px !important;
      }
    }
  }


  .data-pattern-color .gradient{
    width: 4rem !important;
    height: 4rem !important;
  }

  .data-pattern-color .gradient{
    margin: 0 0.4rem !important;
  }


}

/*
 IPAD PRO MEDIA QUERIES
*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
  .media-template-view{
    width: 33.33% !important;
    max-width: 33.33% !important;
    flex: 0 0 33.33% !important;
  }
}

.mat-horizontal-content-container{
  padding: 0 !important;
}


/*****************************************************************************************
 BACKGROUND CUSTOM QR CODE MEDIA QUERIES
******************************************************************************************/

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape){

  .bg-designer{

    .upload-doc-btn{
      width: 100% !important;
    }

    .background-img-container{
      width: 100%;
      height: 290px;
      position: relative;
      margin-top: 10px;
    }

  }

  // Manage the header section
  .header-left-section,.header-right-section,.designer-type{
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  // Choose designer type css
  .designer-type{
    margin-left: 0 !important;
  }



}


/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait){

  .bg-designer{

    .upload-doc-btn{
      width: 100% !important;
    }

    .background-img-container{
      width: 100%;
      height: 290px;
      position: relative;
      margin-top: 10px;
    }

  }

  // Manage the header section
  .header-left-section,.header-right-section,.designer-type{
      flex: 0 0 100% !important;
      max-width: 100% !important;
  }

  // Choose designer type css
  .designer-type{
    margin-left: 0 !important;
  }

}


/*////////////////////////////////////////////////////////////////////////////////////////
 Background Media queries
 /////////////////////////////////////////////////////////////////////////////////////////

*/


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .background-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 100%; height: 180px;
    .background-canvas {

      .canvas-container {

        canvas {
          margin: auto !important;
          display: block !important;

          left: 0 !important;
          top: 0 !important;
          text-align: center !important;
          vertical-align: middle !important;
          //position: relative  !important;
        }

        width: auto !important;
        height: auto !important;


        max-width: 300px !important;
        max-height: 180px !important;

        canvas {
          max-width: 300px !important;
          max-height: 180px !important;
          //position: inherit !important;
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
  .header-right-section {
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

  .background-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 100% !important;
    //height: 180px !important;


    .background-canvas{

      .canvas-container{

        canvas{
          margin: auto !important;
          display: block !important;

          left: 0 !important;
          top: 0 !important;
          text-align: center !important;
          vertical-align: middle  !important;
        }

        width: auto !important;
        height: auto !important;


        max-width: 800px !important;
        max-height: 400px !important;
        canvas{
          max-width: 800px !important;
          max-height: 400px !important;

          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
}


//// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {


  .background-img-container{
    display: flex;
    justify-content: center;
    align-items: center;


    .background-canvas{
      max-width: 98%;
      max-height: 390px;
      .canvas-container{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 98% !important;
        max-height: 390px !important;
        height: 17rem !important;

        canvas{
          //margin: auto !important;
          display: block !important;

          left: auto !important;
          top: auto !important;
          text-align: center !important;
          vertical-align: middle  !important;
        }

        canvas{
          max-width: 98% !important;
          max-height: 390px !important;
          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-width : 768px) and (max-width : 1024px) {

  .background-img-container{
    display: flex;
    justify-content: center;
    align-items: center;

    .background-canvas{

      .canvas-container{

        canvas{
          margin: auto !important;
          display: block !important;

          left: 0 !important;
          top: 0 !important;
          text-align: center !important;
          vertical-align: middle  !important;
        }

        width: auto !important;
        height: auto !important;


        max-width: 350px !important;
        max-height: 500px !important;
        canvas{
          max-width: 350px !important;
          max-height: 500px !important;

          width: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

// Used in QR detail page (QR render)
.background-img-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .background-canvas-qr-detail{
    max-width: 98%;
    max-height: 390px;
    .canvas-container{
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 98% !important;
      max-height: 390px !important;
      height: 17rem !important;

      canvas{
        //margin: auto !important;
        display: block !important;

        left: auto !important;
        top: auto !important;
        text-align: center !important;
        vertical-align: middle  !important;
      }

      canvas{
        max-width: 98% !important;
        max-height: 17rem !important;
        width: auto !important;
        height: auto !important;
      }
    }
  }
}
