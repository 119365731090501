.ng-select-component {

  &.ng-select {
    .ng-select-container {
      border: unset;
      color: $form-select-color;

      .ng-placeholder {
        color: #979797 !important;
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          border-color: $font-color transparent transparent;
        }
      }

      &:hover {
        box-shadow: none;
      }
    }

    &.ng-select-opened {
      .ng-arrow-wrapper {
        .ng-arrow {
          border-color: transparent transparent $font-color;
        }
      }
    }

    &:hover {
      border-color: $secondary;
    }
  }

  &.ng-select-focused, .ng-dropdown-header {
    color: $form-select-color;
  }

  &.ng-select-multiple {
  .ng-value {
    color: $form-select-color !important;
    background-color: $light !important;
  }
  .ng-value-icon {
    color: $form-select-color !important;
  }
  &.sm {
    .ng-placeholder {
      top: 4px !important;
    }
  }
  &.md {
    .ng-placeholder {
      top: 9px !important;
    }
  }
}

  &.form-control {
    padding: 0;
  }

  &.sm {
    font-size: $form-select-font-size-sm;
    .ng-select-container {
      //&.ng-appearance-outline {
        min-height: 31px !important;
        border-radius: $input-border-radius-sm !important;

        //.ng-clear-wrapper {
        //  bottom: 7px !important;
        //}

        //.ng-arrow-wrapper {
        //  bottom: 8px !important;
        //}
      //}

      .ng-value-label {
        font-size: $form-select-font-size-sm !important;
      }
    }

    .ng-dropdown-panel-items {
      .ng-option {
        font-size: $form-select-font-size-sm !important;
        padding: 6px;
      }
    }
  }

  &.md {
    font-size: $form-select-font-size;
    .ng-select-container {
      //&.ng-appearance-outline {
        min-height: 41px !important;
        border-radius: $input-border-radius !important;

        //.ng-clear-wrapper {
        //  bottom: 7px !important;
        //}

        //.ng-arrow-wrapper {
        //  bottom: 11px !important;
        //}
      //}

      .ng-value-label {
        font-size: $form-select-font-size !important;
      }

      .ng-input {
        top: 10px;
      }
    }

    .ng-dropdown-panel-items {
      .ng-option {
        font-size: $form-select-font-size !important;
        padding: 8px;
      }
    }
  }

  &.lg {
    font-size: $form-select-font-size-lg;
    .ng-select-container {
      //&.ng-appearance-outline {
        min-height: 51px !important;
        border-radius: $input-border-radius-lg !important;

        //.ng-clear-wrapper {
        //  bottom: 7px !important;
        //}
        //
        //.ng-arrow-wrapper {
        //  bottom: 11px !important;
        //}
        //
        //&:hover:after {
        //  border-color: $form-select-border-color !important;
        //}
      //}

      .ng-value-label {
        font-size: $form-select-font-size-lg !important;
      }
    }

    .ng-dropdown-panel-items {
      .ng-option {
        font-size: $form-select-font-size-lg !important;
        padding: 11px;
      }
    }
  }

  .ng-select-container {
      &:hover:after {
        border-width: 1px !important;
        border-color: $form-select-border-color !important;
      }
    }

  &.ng-select-primary {
    .ng-select-container {
      background-color: $primary !important;

      .ng-value-container {
        .ng-placeholder, .ng-value {
          color: white !important;
          font-weight: bold;
        }
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          border-color: white transparent transparent;
        }
      }
    }
    &.ng-select-opened {
      .ng-arrow-wrapper {
        .ng-arrow {
          border-color: transparent transparent white;
        }
      }
    }
  }

}

.ng-dropdown-panel-items .ng-option {
  //border-bottom: 1px solid $gray-400;

  //&:hover {
  //  font-weight: bold;
  //}

  &.ng-option-selected {
    color: $form-select-color !important;
  }
}

// Without material styling
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected:not(.ng-option-marked) {
  background-color: transparent !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $light !important;
}

// Using this to solve mobile z-index issue (in qr list)
.ng-dropdown-panel {
  z-index: 1063 !important;
}


// Common for all dropdowns
.ng-dropdown-panel {
  margin: 8px 0 !important;
  border-radius: $form-select-border-radius;

  .ng-dropdown-panel-items {
    border-radius: $form-select-border-radius;
  }
}

.select-group {
  border: $input-btn-border-width solid $border-color;
  border-radius: $border-radius-md;
  .input-group-text, ng-select {
    border: 0;
  }

  &:hover {
    border-color: $secondary;
  }
}
