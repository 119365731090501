@import "colors";

ul {
  &.file-list {
    list-style: none;
    padding-left: unset;

    li {
      &.file-header {
        justify-content: space-between;
        padding-bottom: 0.5rem !important;
      }

      &.item {
        .file-icon {
          line-height: 0;
          margin-right: 0.5rem;
        }
      }

      &:last-child {
        border-bottom: unset;
      }
    }
  }
}
