$g_map_color: #6091EE;

.btn-map {
  &.google-map {
    background: $g_map_color !important;
    border-color: darken($g_map_color, 0.5) !important;
  }
  .map-icon {
    background-image: url("/assets/landing-preview/assets/icons/google_map.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    font-size: 0;
    height: 24px;
    width: 24px;
  }
  span{
    font-weight: unset;
    color: white;
    font-size: 12px;
  }
}

.map-location-size {
  width: 24px;
  margin: 0 1rem 0 1rem;
}
