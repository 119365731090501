.otp-inputs input {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  margin: 0 5px;
  border: 1px solid #ccc;
  outline: none;
}

.otp-inputs input.active {
  border-color: blue;
}
